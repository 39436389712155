import { Button, useWalletModal, ButtonProps, ConnectorNames } from '@pancakeswap/uikit'
import useAuth from 'hooks/useAuth'
// @ts-ignore
// eslint-disable-next-line import/extensions
import { useActiveHandle } from 'hooks/useEagerConnect.bmp.ts'
import { useTranslation } from '@pancakeswap/localization'
import Trans from './Trans'
import { connectorLocalStorageKey, walletLocalStorageKey } from '@pancakeswap/uikit/src/widgets/WalletModal/config'
import { getEip6963CurrProvider } from 'utils/custom-connector'
import { useEffect } from 'react'

const ConnectWalletButton = ({ children, ...props }: ButtonProps) => {
  const { t } = useTranslation()
  const { login } = useAuth()
  const handleActive = useActiveHandle()
  const { onPresentConnectModal } = useWalletModal(login, t)

  // 自动钱包链接，延迟操作保证Window的挂载
  useEffect(()=>{
    setTimeout(() => {
      if (typeof window !== 'undefined'  && getEip6963CurrProvider() && window.localStorage.getItem(connectorLocalStorageKey)) {
        try {
          console.log('try Automatic connect...');
          login(window.localStorage.getItem(connectorLocalStorageKey) as ConnectorNames, window.localStorage.getItem(walletLocalStorageKey) as string)
        } catch (error) {
          console.log('Automatic connection failed: ',error);
        }
      }
    }, 1500);
  },[])

  const handleClick = () => {
    if (typeof __NEZHA_BRIDGE__ !== 'undefined') {
      handleActive()
    } else {
      onPresentConnectModal()
    }
  }

  return (
    <Button onClick={handleClick} {...props} style={{ boxShadow: 'none', background: 'linear-gradient(90deg,#434B34 0%,#000 100%)', borderRadius: '28px' }}>
      {children || <Trans>Connect Wallet</Trans>}
    </Button>
  )
}

export default ConnectWalletButton
