import Metamask from "../../components/Svg/Icons/Metamask";
import OKXWallet from "../../components/Svg/Icons/OKXWallet";
import WalletConnect from "../../components/Svg/Icons/WalletConnect";
import TrustWallet from "../../components/Svg/Icons/TrustWallet";
import MathWallet from "../../components/Svg/Icons/MathWallet";
import TokenPocket from "../../components/Svg/Icons/TokenPocket";
import BinanceChain from "../../components/Svg/Icons/BinanceChain";
import SafePal from "../../components/Svg/Icons/SafePal";
import Coin98 from "../../components/Svg/Icons/Coin98";
import Blocto from "../../components/Svg/Icons/Blocto";
import CoinbaseWallet from "../../components/Svg/Icons/CoinbaseWallet";
import GateWallet from "../../components/Svg/Icons/GateWallet";
import Opera from "../../components/Svg/Icons/Opera";
import { isGateApp } from "../../../../../src/utils/index";
import { ConnectorNames } from "./types";
import { isMobile } from "react-device-detect";
// !对gate特殊处理
var connectorId = isGateApp() ? ConnectorNames.CustomInjected : isMobile ? ConnectorNames.Injected : ConnectorNames.CustomInjected;
var connectors = [
    {
        title: "MetaMask",
        icon: Metamask,
        connectorId: connectorId,
        priority: 1,
        href: "https://metamask.app.link/dapp/https://www.coinfair.xyz/"
    },
    {
        title: "OKX Wallet",
        icon: OKXWallet,
        connectorId: connectorId,
        priority: 2,
        href: "https://www.okx.com/download"
    },
    {
        title: "GateWallet",
        icon: GateWallet,
        connectorId: ConnectorNames.CustomInjected,
        priority: 3,
        href: "https://www.gate.io/web3"
    },
    {
        title: "Trust Wallet",
        icon: TrustWallet,
        connectorId: ConnectorNames.Injected,
        priority: 4,
        href: "https://link.trustwallet.com/open_url?url=https://www.coinfair.xyz/"
    },
    {
        title: "WalletConnect",
        icon: WalletConnect,
        connectorId: ConnectorNames.WalletConnect,
        priority: 5
    },
    {
        title: "Binance Wallet",
        icon: BinanceChain,
        connectorId: ConnectorNames.BSC,
        priority: 6
    },
    {
        title: "Coinbase Wallet",
        icon: CoinbaseWallet,
        connectorId: ConnectorNames.WalletLink,
        priority: 7
    },
    {
        title: "Opera Wallet",
        icon: Opera,
        connectorId: ConnectorNames.Injected,
        priority: function() {
            var ref;
            return "object" !== "undefined" && Boolean(window === null || window === void 0 ? void 0 : (ref = window.ethereum) === null || ref === void 0 ? void 0 : ref.isOpera) ? 0 : 6;
        },
        href: "https://www.opera.com/crypto/next"
    },
    {
        title: "MathWallet",
        icon: MathWallet,
        connectorId: ConnectorNames.Injected,
        priority: 999
    },
    {
        title: "TokenPocket",
        icon: TokenPocket,
        connectorId: ConnectorNames.Injected,
        priority: 999
    },
    {
        title: "SafePal",
        icon: SafePal,
        connectorId: ConnectorNames.Injected,
        priority: 999
    },
    {
        title: "Coin98",
        icon: Coin98,
        connectorId: ConnectorNames.Injected,
        priority: 999
    },
    {
        title: "Blocto",
        icon: Blocto,
        connectorId: ConnectorNames.Blocto,
        priority: 999
    }, 
];
export default connectors;
export var connectorLocalStorageKey = "connectorIdv2";
export var walletLocalStorageKey = "wallet";
export var walletConnectConfig = connectors.find(function(c) {
    return c.title === "WalletConnect";
});
