import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
// eslint-disable-next-line import/no-unresolved
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { ChainId } from '@pancakeswap/sdk'
import { bscRpcProvider } from 'utils/providers'
import { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { memoize } from 'lodash'
import { NETWORK_CONFIG } from 'config'
import { setupNetwork } from 'utils/wallet'
import { isGateApp } from 'utils'

export const getChainId = memoize((chainName: string):number|undefined => {
  if (!chainName) return undefined
  const curr = Object.entries(NETWORK_CONFIG).filter(v=>v[1].network.toLocaleLowerCase() === chainName.toLocaleLowerCase())[0];
  return curr ? Number(curr[0]) : undefined;
})

export function useNetworkConnectorUpdater() {
  const { chainId } = useWeb3React()
  const router = useRouter()

  useEffect(() => {
    const parsedQueryChainId = getChainId(router.query.chain as string)
    if (parsedQueryChainId !== chainId && parsedQueryChainId) {
      setupNetwork(parsedQueryChainId);
    }
  }, [chainId, router])
}


/**
 * Provides a web3 provider with or without user's signer
 * Recreate web3 instance only if the provider change
 */
const useActiveWeb3React = (): Web3ReactContextInterface<Web3Provider> => {
  const { library, chainId, ...web3React } = useWeb3React()
  // !gate
  return { 
    library: library ?? bscRpcProvider,
    chainId: isGateApp() ? ChainId.BSC : (chainId ?? ChainId.BSC),
    ...web3React
  }
}

export default useActiveWeb3React
