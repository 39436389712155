import { Box, Text, UserMenu, UserMenuDivider, UserMenuItem } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import Image from 'next/image'
import { setupNetwork } from 'utils/wallet'
import { ChainId, NETWORK_CONFIG } from 'config'
import { useWeb3React } from '@web3-react/core'
import { useNetworkConnectorUpdater } from 'hooks/useActiveWeb3React'
import { useRouter } from 'next/router'
import { isGateApp } from 'utils'
import { is } from 'date-fns/locale'
import { useEffect } from 'react'

const chainsIdList = Object.keys(NETWORK_CONFIG);

const swapElements = (arr, elem1, elem2)=>{
  const index1 = arr.indexOf(elem1);
  const index2 = arr.indexOf(elem2);
  if (index1 !== -1 && index2 !== -1) {
      [arr[index1], arr[index2]] = [arr[index2], arr[index1]];
  }
  return arr;
}

export const SUPPORTED_CHAINS = chainsIdList.filter((chain) => {
  if (process.env.NEXT_PUBLIC_SUPPORTED_CHAINID) {
    // eslint-disable-next-line no-console
    return process.env.NEXT_PUBLIC_SUPPORTED_CHAINID.split(',').indexOf(chain) > -1
  }
  return false
})

swapElements(SUPPORTED_CHAINS,"8453","185");



export const NetworkSelect = () => {
  const { t } = useTranslation()

  const router = useRouter();
  const setupNetworkHandle = async(chainId:number)=>{
    const res = await setupNetwork(chainId);
    if(!res) return;
    const { pathname, query } = router;
    const newQuery = { ...query };
    ['chain', 'outputCurrency', 'inputCurrency', 'recipient'].forEach((param) => {
        if (newQuery[param]) {
            delete newQuery[param];
        }
    });
    // 使用 router.replace 更新路由，不刷新页面
    router.replace({
        pathname,
        query: newQuery
    }, undefined, { shallow: true });
  }

  // ! gate手动触发一次选择
  useEffect(() => {
    const fullUrl = window.location.href;
    // 通过seesion storage 让页面只刷新一次
    if (isGateApp() && !sessionStorage.getItem('gateappRefreshed')) {
      setupNetworkHandle(ChainId.BSC);
      sessionStorage.setItem('gateappRefreshed', 'true');
      setTimeout(() => {
        // window.location.reload();
        window.location.href = fullUrl;
      }, 500);
    }
  }, [])

  return (
    <>
      <Box px="16px" py="8px">
        <Text fontWeight={600}>{t('Select a Network')}</Text>
      </Box>
      {/* <UserMenuDivider /> */}
      {SUPPORTED_CHAINS.length ? <UserMenuDivider /> : null}
      {(isGateApp() ? [ChainId.BSC] : SUPPORTED_CHAINS).map((chain) => (
        <UserMenuItem key={chain} style={{ justifyContent: 'flex-start' }} onClick={() => setupNetworkHandle(parseInt(chain))}>
          <Image width={24} height={24} src={`/images/chains/${chain}.png`} unoptimized />
          <Text pl="12px">{NETWORK_CONFIG[chain].name}</Text>
        </UserMenuItem>
      ))}
    </>
  )
}

export const NetworkSwitcher = () => {
  let { chainId } = useWeb3React()
  // !gate调整
  chainId = isGateApp() ? ChainId.BSC : chainId;
  useNetworkConnectorUpdater()
  return (
    <UserMenu
      mr="8px"
      avatarSrc={chainId ? `/images/chains/${chainId}.png` : ''}
      account={
        NETWORK_CONFIG[SUPPORTED_CHAINS.find(chain => (Number(chain) === Number(chainId)))]?.name
      }
      ellipsis={false}
    >
      {() => <NetworkSelect />}
    </UserMenu>
  )
}
